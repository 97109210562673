/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, Cookie) => {
        // VARIABLES - Window
        var WINDOW_width = window.innerWidth;
        var WINDOW_BREAK = 1000;

        // FUNCTIONS - Resize Window
        function resize_window() {
            WINDOW_width = window.innerWidth;

            if(WINDOW_width > WINDOW_BREAK) {
                var ABOUT_slider = new Swiper('.slider-about', {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
                var NEWS_slider = new Swiper('.slider-news', {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    centeredSlides: true,
                    loop: false,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            } else {
                var ABOUT_slider = new Swiper('.slider-about', {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
                var NEWS_slider = new Swiper('.slider-news', {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    centeredSlides: false,
                    loop: false,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            }
        }

        // DOM - Listener
        // window.addEventListener('resize', resize_window);

        // DOM - Swipper
        var ABOUT_slider = new Swiper('.slider-about', {
            slidesPerView: 3,
            spaceBetween: 30,
            loop: true,
            breakpoints: {
                1000: {
                  slidesPerView: 1,
                  spaceBetween: 40
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        var NEWS_slider = new Swiper('.slider-news', {
            slidesPerView: 3,
            spaceBetween: 30,
            centeredSlides: true,
            loop: false,
            breakpoints: {
                1000: {
                  slidesPerView: 1,
                  spaceBetween: 40
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        // if(WINDOW_width > WINDOW_BREAK) {
        //     var ABOUT_slider = new Swiper('.slider-about', {
        //         slidesPerView: 3,
        //         spaceBetween: 30,
        //         loop: true,
        //         navigation: {
        //             nextEl: '.swiper-button-next',
        //             prevEl: '.swiper-button-prev',
        //         },
        //     });
        //     var NEWS_slider = new Swiper('.slider-news', {
        //         slidesPerView: 3,
        //         spaceBetween: 30,
        //         centeredSlides: true,
        //         loop: false,
        //         navigation: {
        //             nextEl: '.swiper-button-next',
        //             prevEl: '.swiper-button-prev',
        //         },
        //     });
        // } else {
        //     var ABOUT_slider = new Swiper('.slider-about', {
        //         slidesPerView: 1,
        //         spaceBetween: 30,
        //         loop: true,
        //         navigation: {
        //             nextEl: '.swiper-button-next',
        //             prevEl: '.swiper-button-prev',
        //         },
        //     });
        //     var NEWS_slider = new Swiper('.slider-news', {
        //         slidesPerView: 1,
        //         spaceBetween: 30,
        //         centeredSlides: false,
        //         loop: false,
        //         navigation: {
        //             nextEl: '.swiper-button-next',
        //             prevEl: '.swiper-button-prev',
        //         },
        //     });
        // }

        /* ------------------------------------------------------------- */

        // FUNCTIONS - Enter form
        function enter_form() {
            var label = this.parentNode.querySelector('label');
            label.style.transform = 'translateY(0px)';
            label.style.opacity = '1';
            document.querySelector('.home').addEventListener('click', out_form, true);
        }
        // FUNCTIONS - Out form
        function out_form() {
            for (var ginput of ginputs) {
                if (ginput.querySelector('.large').value == '') {
                    ginput.parentNode.querySelector('label').style.transform = 'translateY(37px)';
                }
                ginput.parentNode.querySelector('label').style.opacity = '0.5';
            }
            document.querySelector('.home').removeEventListener('click', out_form, true);
        }

        // DOM - Form
        var ginputs = document.querySelectorAll('.ginput_container');
        for (var ginput of ginputs) {
            // ginput.addEventListener('mouseenter', enter_form);
            // ginput.addEventListener('mouseout', out_form);
            ginput.addEventListener('click', enter_form);
        }

        /* ------------------------------------------------------------- */
                                // add-ons TB
        /* ------------------------------------------------------------- */

        // Mobile Menu
        $('.mobile-menu').on('click', function() {
            $(this).parent().toggleClass('open');
            $(this).parent().find('#nav').fadeToggle();
        })

        $('nav #nav li a').on('click', function() {
            if ($(this).closest('nav').hasClass('open')){
                $(this).closest('nav').removeClass('open');
                $(this).closest('#nav').fadeOut();
            };
        })

        // Go to target
        var header_height = $('.home > header').outerHeight();
        window.addEventListener('resize', function(){header_height = $('.home > header').outerHeight();})
        TweenLite.to(window, 2, {scrollTo:{y:window.location.hash, offsetY:header_height}});
        window.location.hash = "";

        $('.goto').on('click', function(event) {
            var target = $(this).data('href');

            if (target == '#intro'){
                header_height = $('.home > header').outerHeight() + 30;
            }

            if($('body').hasClass('home')) {
                TweenLite.to(window, 2, {scrollTo:{y:target, offsetY:header_height}});
                window.location.hash = "";

                if (target == '#contact'){
                    $('.more').fadeOut('fast',function(){
                        $('.ask').fadeIn().addClass('open');
                    });
                }
                event.preventDefault();
                return false;
            }
        });

        $('#btn-contact').on('click', function(event) {
            TweenLite.to(window, 2, {scrollTo:{y:'#contact', offsetY:header_height}});
            $('.more').fadeOut('fast',function(){
                $('.ask').fadeIn().addClass('open');
            });
            event.preventDefault();
            return false;
        })

        //cookies
		if (Cookie.get('legals') != 'true' ){
			$('#cookie').fadeIn('slow');
		}

		$('#cookie #legals_btn').on('click',function(){
			Cookie.set('legals', 'true');
			$(this).closest('#cookie').fadeOut();
			return false;
		})
    }
}

