/*** IMPORTS FROM imports-loader ***/
var define = false;

import { library, dom } from '@fortawesome/fontawesome-svg-core/index';
import { faWordpress } from '@fortawesome/free-brands-svg-icons'; // prefix: fab
import { faFire as fasFaFire } from '@fortawesome/pro-solid-svg-icons'; // prefix: fas
import {
    faFire as farFaFire,
    faBars as farBars,
    faComments as farComments,
    faPlus as farPlus
} from '@fortawesome/pro-regular-svg-icons'; // prefix: far
import {
    faFire as falFaFire,
    faAngleRight as falAngleRight,
    faAngleDown as falAngleDown,
    faAngleUp as falAngleUp,
} from '@fortawesome/pro-light-svg-icons'; // prefix: fal

library.add(
    faWordpress,
    falFaFire,
    falAngleRight,
    falAngleDown,
    falAngleUp,
    farFaFire,
    farBars,
    farComments,
    farPlus,
    fasFaFire,
)
dom.watch();

